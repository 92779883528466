import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4b497e46 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _1bbd6b92 = () => interopDefault(import('../pages/accept-invitation.vue' /* webpackChunkName: "pages/accept-invitation" */))
const _7b3b3a3c = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _ba7bed2e = () => interopDefault(import('../pages/bloomberg-2024.vue' /* webpackChunkName: "pages/bloomberg-2024" */))
const _6c361940 = () => interopDefault(import('../pages/brand.vue' /* webpackChunkName: "pages/brand" */))
const _1aa6ffb4 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _7856489a = () => interopDefault(import('../pages/brokers.vue' /* webpackChunkName: "pages/brokers" */))
const _6dd1e688 = () => interopDefault(import('../pages/brokers/settings/general.vue' /* webpackChunkName: "pages/brokers/settings/general" */))
const _1ccfe6f0 = () => interopDefault(import('../pages/brokers/settings/notifications.vue' /* webpackChunkName: "pages/brokers/settings/notifications" */))
const _58241592 = () => interopDefault(import('../pages/buyers.vue' /* webpackChunkName: "pages/buyers" */))
const _3625767a = () => interopDefault(import('../pages/buyers/index.vue' /* webpackChunkName: "pages/buyers/index" */))
const _0bcc599e = () => interopDefault(import('../pages/buyers/analytics/index.vue' /* webpackChunkName: "pages/buyers/analytics/index" */))
const _7244aca0 = () => interopDefault(import('../pages/buyers/orders/index.vue' /* webpackChunkName: "pages/buyers/orders/index" */))
const _a7864010 = () => interopDefault(import('../pages/buyers/payments/index.vue' /* webpackChunkName: "pages/buyers/payments/index" */))
const _4375b3f4 = () => interopDefault(import('../pages/buyers/samples/index.vue' /* webpackChunkName: "pages/buyers/samples/index" */))
const _6d32516e = () => interopDefault(import('../pages/buyers/settings/index.vue' /* webpackChunkName: "pages/buyers/settings/index" */))
const _73c2d16f = () => interopDefault(import('../pages/buyers/payments/credit-memos.vue' /* webpackChunkName: "pages/buyers/payments/credit-memos" */))
const _257a069e = () => interopDefault(import('../pages/buyers/payments/statements/index.vue' /* webpackChunkName: "pages/buyers/payments/statements/index" */))
const _0d268584 = () => interopDefault(import('../pages/buyers/settings/general.vue' /* webpackChunkName: "pages/buyers/settings/general" */))
const _0d4090f8 = () => interopDefault(import('../pages/buyers/settings/invite-colleagues.vue' /* webpackChunkName: "pages/buyers/settings/invite-colleagues" */))
const _25bf02f8 = () => interopDefault(import('../pages/buyers/settings/notifications.vue' /* webpackChunkName: "pages/buyers/settings/notifications" */))
const _7cf3efa2 = () => interopDefault(import('../pages/buyers/settings/payment.vue' /* webpackChunkName: "pages/buyers/settings/payment" */))
const _ee47bb0c = () => interopDefault(import('../pages/buyers/orders/list/_type.vue' /* webpackChunkName: "pages/buyers/orders/list/_type" */))
const _4263792d = () => interopDefault(import('../pages/buyers/orders/pre-order/_id.vue' /* webpackChunkName: "pages/buyers/orders/pre-order/_id" */))
const _a0783a94 = () => interopDefault(import('../pages/buyers/samples/list/_type.vue' /* webpackChunkName: "pages/buyers/samples/list/_type" */))
const _0fab4fd0 = () => interopDefault(import('../pages/buyers/orders/_id.vue' /* webpackChunkName: "pages/buyers/orders/_id" */))
const _3508235c = () => interopDefault(import('../pages/buyers/samples/_id.vue' /* webpackChunkName: "pages/buyers/samples/_id" */))
const _29f1e7b8 = () => interopDefault(import('../pages/capital/index.vue' /* webpackChunkName: "pages/capital/index" */))
const _6d5f4b2e = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _265c8ef7 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _cf81b2c6 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _edabd916 = () => interopDefault(import('../pages/claim.vue' /* webpackChunkName: "pages/claim" */))
const _c0780764 = () => interopDefault(import('../pages/claims.vue' /* webpackChunkName: "pages/claims" */))
const _65f42d8f = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _37c60cd2 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _2beb1608 = () => interopDefault(import('../pages/faq/brands.vue' /* webpackChunkName: "pages/faq/brands" */))
const _24eb0503 = () => interopDefault(import('../pages/faq/retailers.vue' /* webpackChunkName: "pages/faq/retailers" */))
const _a08dd0e0 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _49bc4430 = () => interopDefault(import('../pages/growth-deck.vue' /* webpackChunkName: "pages/growth-deck" */))
const _4db8411d = () => interopDefault(import('../pages/head-buyer.vue' /* webpackChunkName: "pages/head-buyer" */))
const _46e8d9d2 = () => interopDefault(import('../pages/head-buyer/analytics/index.vue' /* webpackChunkName: "pages/head-buyer/analytics/index" */))
const _0ab5050a = () => interopDefault(import('../pages/head-buyer/fill-rate/index.vue' /* webpackChunkName: "pages/head-buyer/fill-rate/index" */))
const _5a096eda = () => interopDefault(import('../pages/head-buyer/inventory-velocity/index.vue' /* webpackChunkName: "pages/head-buyer/inventory-velocity/index" */))
const _26af38ec = () => interopDefault(import('../pages/head-buyer/orders/index.vue' /* webpackChunkName: "pages/head-buyer/orders/index" */))
const _eb7ee95c = () => interopDefault(import('../pages/head-buyer/payments/index.vue' /* webpackChunkName: "pages/head-buyer/payments/index" */))
const _51fb954c = () => interopDefault(import('../pages/head-buyer/samples/index.vue' /* webpackChunkName: "pages/head-buyer/samples/index" */))
const _4b35fcc8 = () => interopDefault(import('../pages/head-buyer/settings/index.vue' /* webpackChunkName: "pages/head-buyer/settings/index" */))
const _40726329 = () => interopDefault(import('../pages/head-buyer/analytics/avg-drop-size.vue' /* webpackChunkName: "pages/head-buyer/analytics/avg-drop-size" */))
const _718217f4 = () => interopDefault(import('../pages/head-buyer/analytics/avg-order-size.vue' /* webpackChunkName: "pages/head-buyer/analytics/avg-order-size" */))
const _6d837d12 = () => interopDefault(import('../pages/head-buyer/analytics/best-selling-brands.vue' /* webpackChunkName: "pages/head-buyer/analytics/best-selling-brands" */))
const _2b921aa2 = () => interopDefault(import('../pages/head-buyer/analytics/fill-rate.vue' /* webpackChunkName: "pages/head-buyer/analytics/fill-rate" */))
const _7ce2333c = () => interopDefault(import('../pages/head-buyer/analytics/number-of-orders.vue' /* webpackChunkName: "pages/head-buyer/analytics/number-of-orders" */))
const _f3980730 = () => interopDefault(import('../pages/head-buyer/analytics/sale-velocity.vue' /* webpackChunkName: "pages/head-buyer/analytics/sale-velocity" */))
const _f2cf08d4 = () => interopDefault(import('../pages/head-buyer/analytics/stock-report.vue' /* webpackChunkName: "pages/head-buyer/analytics/stock-report" */))
const _3b49b20e = () => interopDefault(import('../pages/head-buyer/orders/create-multiple.vue' /* webpackChunkName: "pages/head-buyer/orders/create-multiple" */))
const _4a828cd5 = () => interopDefault(import('../pages/head-buyer/payments/credit-memos.vue' /* webpackChunkName: "pages/head-buyer/payments/credit-memos" */))
const _c56ad5d2 = () => interopDefault(import('../pages/head-buyer/payments/statements/index.vue' /* webpackChunkName: "pages/head-buyer/payments/statements/index" */))
const _78ecc25e = () => interopDefault(import('../pages/head-buyer/settings/general.vue' /* webpackChunkName: "pages/head-buyer/settings/general" */))
const _96a88df2 = () => interopDefault(import('../pages/head-buyer/orders/pre-order/_id.vue' /* webpackChunkName: "pages/head-buyer/orders/pre-order/_id" */))
const _42ee5110 = () => interopDefault(import('../pages/head-buyer/samples/list/_type.vue' /* webpackChunkName: "pages/head-buyer/samples/list/_type" */))
const _512dc072 = () => interopDefault(import('../pages/head-buyer/orders/_id.vue' /* webpackChunkName: "pages/head-buyer/orders/_id" */))
const _071c737c = () => interopDefault(import('../pages/head-buyer/samples/_id.vue' /* webpackChunkName: "pages/head-buyer/samples/_id" */))
const _4586805a = () => interopDefault(import('../pages/help-center.vue' /* webpackChunkName: "pages/help-center" */))
const _5363db5d = () => interopDefault(import('../pages/help-center/index.vue' /* webpackChunkName: "pages/help-center/index" */))
const _76532ca6 = () => interopDefault(import('../pages/help-center/search.vue' /* webpackChunkName: "pages/help-center/search" */))
const _51ad3a15 = () => interopDefault(import('../pages/help-center/_slug.vue' /* webpackChunkName: "pages/help-center/_slug" */))
const _34a8fc22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _588f931b = () => interopDefault(import('../pages/lp.vue' /* webpackChunkName: "pages/lp" */))
const _4d6a195e = () => interopDefault(import('../pages/lp/index.vue' /* webpackChunkName: "pages/lp/index" */))
const _e6b9ca30 = () => interopDefault(import('../pages/lp/inventory.vue' /* webpackChunkName: "pages/lp/inventory" */))
const _a64c592a = () => interopDefault(import('../pages/lp/inventory/index.vue' /* webpackChunkName: "pages/lp/inventory/index" */))
const _4db5d3fd = () => interopDefault(import('../pages/lp/inventory/all/index.vue' /* webpackChunkName: "pages/lp/inventory/all/index" */))
const _67914197 = () => interopDefault(import('../pages/lp/inventory/dispose-donate/index.vue' /* webpackChunkName: "pages/lp/inventory/dispose-donate/index" */))
const _70685f55 = () => interopDefault(import('../pages/lp/inventory/inbound/index.vue' /* webpackChunkName: "pages/lp/inventory/inbound/index" */))
const _62fd6710 = () => interopDefault(import('../pages/lp/inventory/withdrawal-requests/index.vue' /* webpackChunkName: "pages/lp/inventory/withdrawal-requests/index" */))
const _5368868b = () => interopDefault(import('../pages/lp/inventory/all/new.vue' /* webpackChunkName: "pages/lp/inventory/all/new" */))
const _3fb2b1a5 = () => interopDefault(import('../pages/lp/inventory/all/_id.vue' /* webpackChunkName: "pages/lp/inventory/all/_id" */))
const _263e8482 = () => interopDefault(import('../pages/lp/inventory/dispose-donate/_id.vue' /* webpackChunkName: "pages/lp/inventory/dispose-donate/_id" */))
const _2fc032fd = () => interopDefault(import('../pages/lp/inventory/inbound/_id.vue' /* webpackChunkName: "pages/lp/inventory/inbound/_id" */))
const _18680ce0 = () => interopDefault(import('../pages/lp/inventory/withdrawal-requests/_id.vue' /* webpackChunkName: "pages/lp/inventory/withdrawal-requests/_id" */))
const _83ea8368 = () => interopDefault(import('../pages/lp/orders/index.vue' /* webpackChunkName: "pages/lp/orders/index" */))
const _7c5070c7 = () => interopDefault(import('../pages/lp/pod-freight.vue' /* webpackChunkName: "pages/lp/pod-freight" */))
const _25fdf3ec = () => interopDefault(import('../pages/lp/pod-freight/index.vue' /* webpackChunkName: "pages/lp/pod-freight/index" */))
const _c765fa1c = () => interopDefault(import('../pages/lp/pod-freight/_id.vue' /* webpackChunkName: "pages/lp/pod-freight/_id" */))
const _4d6caf0a = () => interopDefault(import('../pages/lp/settings/index.vue' /* webpackChunkName: "pages/lp/settings/index" */))
const _6f8027c0 = () => interopDefault(import('../pages/lp/settings/general.vue' /* webpackChunkName: "pages/lp/settings/general" */))
const _7ce83898 = () => interopDefault(import('../pages/lp/orders/_id.vue' /* webpackChunkName: "pages/lp/orders/_id" */))
const _88d3ac94 = () => interopDefault(import('../pages/order-guide.vue' /* webpackChunkName: "pages/order-guide" */))
const _e3de6776 = () => interopDefault(import('../pages/ordered-variants.vue' /* webpackChunkName: "pages/ordered-variants" */))
const _2b3cc360 = () => interopDefault(import('../pages/pod-3pl.vue' /* webpackChunkName: "pages/pod-3pl" */))
const _dd4d07a8 = () => interopDefault(import('../pages/pod-cost.vue' /* webpackChunkName: "pages/pod-cost" */))
const _34d27ab4 = () => interopDefault(import('../pages/pod-freight.vue' /* webpackChunkName: "pages/pod-freight" */))
const _eea25c8c = () => interopDefault(import('../pages/pod-insights.vue' /* webpackChunkName: "pages/pod-insights" */))
const _a5ad6ad0 = () => interopDefault(import('../pages/pod-pax.vue' /* webpackChunkName: "pages/pod-pax" */))
const _781114c9 = () => interopDefault(import('../pages/podcasts/index.vue' /* webpackChunkName: "pages/podcasts/index" */))
const _64e8b3fc = () => interopDefault(import('../pages/press.vue' /* webpackChunkName: "pages/press" */))
const _7a110d02 = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _0ee836fe = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _46078268 = () => interopDefault(import('../pages/product.vue' /* webpackChunkName: "pages/product" */))
const _3251f9fe = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _aea90e72 = () => interopDefault(import('../pages/promotions.vue' /* webpackChunkName: "pages/promotions" */))
const _0fcb776d = () => interopDefault(import('../pages/recommended-products.vue' /* webpackChunkName: "pages/recommended-products" */))
const _85d55d18 = () => interopDefault(import('../pages/referral.vue' /* webpackChunkName: "pages/referral" */))
const _623a147d = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _6aaeb5f0 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _74c38cbc = () => interopDefault(import('../pages/retailers.vue' /* webpackChunkName: "pages/retailers" */))
const _2711f382 = () => interopDefault(import('../pages/setup-password.vue' /* webpackChunkName: "pages/setup-password" */))
const _c548bcac = () => interopDefault(import('../pages/state-of-grocery.vue' /* webpackChunkName: "pages/state-of-grocery" */))
const _7af72098 = () => interopDefault(import('../pages/team.vue' /* webpackChunkName: "pages/team" */))
const _656e7b9c = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _097edd1f = () => interopDefault(import('../pages/terms-of-service/index.vue' /* webpackChunkName: "pages/terms-of-service/index" */))
const _4f6bae4f = () => interopDefault(import('../pages/terms-of-service/brands.vue' /* webpackChunkName: "pages/terms-of-service/brands" */))
const _151a92b0 = () => interopDefault(import('../pages/terms-of-service/logistics-partners.vue' /* webpackChunkName: "pages/terms-of-service/logistics-partners" */))
const _5c0e3260 = () => interopDefault(import('../pages/terms-of-service/retailers.vue' /* webpackChunkName: "pages/terms-of-service/retailers" */))
const _c734fe24 = () => interopDefault(import('../pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))
const _b8ec88b8 = () => interopDefault(import('../pages/vendors.vue' /* webpackChunkName: "pages/vendors" */))
const _3d5bf727 = () => interopDefault(import('../pages/vendors/index.vue' /* webpackChunkName: "pages/vendors/index" */))
const _5c34495b = () => interopDefault(import('../pages/vendors/analytics.vue' /* webpackChunkName: "pages/vendors/analytics" */))
const _2485c0c4 = () => interopDefault(import('../pages/vendors/analytics/index.vue' /* webpackChunkName: "pages/vendors/analytics/index" */))
const _106399c2 = () => interopDefault(import('../pages/vendors/analytics/customer.vue' /* webpackChunkName: "pages/vendors/analytics/customer" */))
const _845b7276 = () => interopDefault(import('../pages/vendors/analytics/customer/index.vue' /* webpackChunkName: "pages/vendors/analytics/customer/index" */))
const _6ddca3df = () => interopDefault(import('../pages/vendors/analytics/customer/churn.vue' /* webpackChunkName: "pages/vendors/analytics/customer/churn" */))
const _fdfbf9dc = () => interopDefault(import('../pages/vendors/analytics/customer/cohort.vue' /* webpackChunkName: "pages/vendors/analytics/customer/cohort" */))
const _94e20554 = () => interopDefault(import('../pages/vendors/analytics/customer/first-time-and-returning.vue' /* webpackChunkName: "pages/vendors/analytics/customer/first-time-and-returning" */))
const _3f933b4e = () => interopDefault(import('../pages/vendors/analytics/customer/growth-accounting.vue' /* webpackChunkName: "pages/vendors/analytics/customer/growth-accounting" */))
const _24698f9e = () => interopDefault(import('../pages/vendors/analytics/customer/one-time.vue' /* webpackChunkName: "pages/vendors/analytics/customer/one-time" */))
const _759aca99 = () => interopDefault(import('../pages/vendors/analytics/customer/over-time.vue' /* webpackChunkName: "pages/vendors/analytics/customer/over-time" */))
const _c1361348 = () => interopDefault(import('../pages/vendors/analytics/customer/sales-by-region.vue' /* webpackChunkName: "pages/vendors/analytics/customer/sales-by-region" */))
const _23890128 = () => interopDefault(import('../pages/vendors/analytics/inventory.vue' /* webpackChunkName: "pages/vendors/analytics/inventory" */))
const _526becaa = () => interopDefault(import('../pages/vendors/analytics/inventory/index.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/index" */))
const _51d11926 = () => interopDefault(import('../pages/vendors/analytics/inventory/average-sold-per-week.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/average-sold-per-week" */))
const _5f252692 = () => interopDefault(import('../pages/vendors/analytics/inventory/current.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/current" */))
const _03216c8f = () => interopDefault(import('../pages/vendors/analytics/inventory/days-of-remaining.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/days-of-remaining" */))
const _21941b9a = () => interopDefault(import('../pages/vendors/analytics/inventory/lost-sales.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/lost-sales" */))
const _dcb38b32 = () => interopDefault(import('../pages/vendors/analytics/inventory/month-end.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/month-end" */))
const _dd6e15ca = () => interopDefault(import('../pages/vendors/analytics/inventory/percent-of-sold.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/percent-of-sold" */))
const _3254cea4 = () => interopDefault(import('../pages/vendors/analytics/inventory/replenishment-lead-time.vue' /* webpackChunkName: "pages/vendors/analytics/inventory/replenishment-lead-time" */))
const _db40f52c = () => interopDefault(import('../pages/vendors/analytics/lead-time.vue' /* webpackChunkName: "pages/vendors/analytics/lead-time" */))
const _b1ee5726 = () => interopDefault(import('../pages/vendors/analytics/lead-time/index.vue' /* webpackChunkName: "pages/vendors/analytics/lead-time/index" */))
const _9bc6350a = () => interopDefault(import('../pages/vendors/analytics/lead-time/pd-order-fulfillment.vue' /* webpackChunkName: "pages/vendors/analytics/lead-time/pd-order-fulfillment" */))
const _df0a99c2 = () => interopDefault(import('../pages/vendors/analytics/lead-time/replenishment.vue' /* webpackChunkName: "pages/vendors/analytics/lead-time/replenishment" */))
const _add62e22 = () => interopDefault(import('../pages/vendors/analytics/promotion.vue' /* webpackChunkName: "pages/vendors/analytics/promotion" */))
const _b2f67f9c = () => interopDefault(import('../pages/vendors/analytics/promotion/index.vue' /* webpackChunkName: "pages/vendors/analytics/promotion/index" */))
const _095835c2 = () => interopDefault(import('../pages/vendors/analytics/promotion/leading-to-repurchase.vue' /* webpackChunkName: "pages/vendors/analytics/promotion/leading-to-repurchase" */))
const _04cb1581 = () => interopDefault(import('../pages/vendors/analytics/promotion/usage.vue' /* webpackChunkName: "pages/vendors/analytics/promotion/usage" */))
const _c4d2f8aa = () => interopDefault(import('../pages/vendors/analytics/sale.vue' /* webpackChunkName: "pages/vendors/analytics/sale" */))
const _62034424 = () => interopDefault(import('../pages/vendors/analytics/sale/index.vue' /* webpackChunkName: "pages/vendors/analytics/sale/index" */))
const _65108153 = () => interopDefault(import('../pages/vendors/analytics/sale/avg-order-value.vue' /* webpackChunkName: "pages/vendors/analytics/sale/avg-order-value" */))
const _1403cfbe = () => interopDefault(import('../pages/vendors/analytics/sale/fulfilled-gmv.vue' /* webpackChunkName: "pages/vendors/analytics/sale/fulfilled-gmv" */))
const _37c7628a = () => interopDefault(import('../pages/vendors/analytics/sale/fulfilled-revenue.vue' /* webpackChunkName: "pages/vendors/analytics/sale/fulfilled-revenue" */))
const _1154420c = () => interopDefault(import('../pages/vendors/analytics/sale/ordered-gmv.vue' /* webpackChunkName: "pages/vendors/analytics/sale/ordered-gmv" */))
const _57d9fdd8 = () => interopDefault(import('../pages/vendors/analytics/sale/ordered-revenue.vue' /* webpackChunkName: "pages/vendors/analytics/sale/ordered-revenue" */))
const _176096c8 = () => interopDefault(import('../pages/vendors/analytics/sale/sku-performance.vue' /* webpackChunkName: "pages/vendors/analytics/sale/sku-performance" */))
const _0535eaec = () => interopDefault(import('../pages/vendors/brands/index.vue' /* webpackChunkName: "pages/vendors/brands/index" */))
const _60821ab5 = () => interopDefault(import('../pages/vendors/claims/index.vue' /* webpackChunkName: "pages/vendors/claims/index" */))
const _516eec31 = () => interopDefault(import('../pages/vendors/inventory.vue' /* webpackChunkName: "pages/vendors/inventory" */))
const _796bc9f4 = () => interopDefault(import('../pages/vendors/inventory/index.vue' /* webpackChunkName: "pages/vendors/inventory/index" */))
const _2631147a = () => interopDefault(import('../pages/vendors/inventory/all.vue' /* webpackChunkName: "pages/vendors/inventory/all" */))
const _adb77024 = () => interopDefault(import('../pages/vendors/inventory/dispose-donate/index.vue' /* webpackChunkName: "pages/vendors/inventory/dispose-donate/index" */))
const _3743bcde = () => interopDefault(import('../pages/vendors/inventory/inbound/index.vue' /* webpackChunkName: "pages/vendors/inventory/inbound/index" */))
const _ce24f980 = () => interopDefault(import('../pages/vendors/inventory/status.vue' /* webpackChunkName: "pages/vendors/inventory/status" */))
const _71dd28fc = () => interopDefault(import('../pages/vendors/inventory/transfer/index.vue' /* webpackChunkName: "pages/vendors/inventory/transfer/index" */))
const _6257a481 = () => interopDefault(import('../pages/vendors/inventory/withdrawal-requests/index.vue' /* webpackChunkName: "pages/vendors/inventory/withdrawal-requests/index" */))
const _b9cdfee0 = () => interopDefault(import('../pages/vendors/inventory/dispose-donate/create.vue' /* webpackChunkName: "pages/vendors/inventory/dispose-donate/create" */))
const _4e2facc0 = () => interopDefault(import('../pages/vendors/inventory/inbound/create.vue' /* webpackChunkName: "pages/vendors/inventory/inbound/create" */))
const _1051365d = () => interopDefault(import('../pages/vendors/inventory/withdrawal-requests/create.vue' /* webpackChunkName: "pages/vendors/inventory/withdrawal-requests/create" */))
const _59412454 = () => interopDefault(import('../pages/vendors/inventory/dispose-donate/_id.vue' /* webpackChunkName: "pages/vendors/inventory/dispose-donate/_id" */))
const _17b75ec6 = () => interopDefault(import('../pages/vendors/inventory/inbound/_id.vue' /* webpackChunkName: "pages/vendors/inventory/inbound/_id" */))
const _994d8b38 = () => interopDefault(import('../pages/vendors/inventory/transfer/_id.vue' /* webpackChunkName: "pages/vendors/inventory/transfer/_id" */))
const _0cd6d329 = () => interopDefault(import('../pages/vendors/inventory/withdrawal-requests/_id.vue' /* webpackChunkName: "pages/vendors/inventory/withdrawal-requests/_id" */))
const _756d4986 = () => interopDefault(import('../pages/vendors/notifications.vue' /* webpackChunkName: "pages/vendors/notifications" */))
const _20827c63 = () => interopDefault(import('../pages/vendors/orders/index.vue' /* webpackChunkName: "pages/vendors/orders/index" */))
const _43c1f968 = () => interopDefault(import('../pages/vendors/payments.vue' /* webpackChunkName: "pages/vendors/payments" */))
const _2ff7d1eb = () => interopDefault(import('../pages/vendors/payments/index.vue' /* webpackChunkName: "pages/vendors/payments/index" */))
const _02e59b22 = () => interopDefault(import('../pages/vendors/payments/commission.vue' /* webpackChunkName: "pages/vendors/payments/commission" */))
const _669f6234 = () => interopDefault(import('../pages/vendors/payments/payouts.vue' /* webpackChunkName: "pages/vendors/payments/payouts" */))
const _3b9764de = () => interopDefault(import('../pages/vendors/payments/statements/index.vue' /* webpackChunkName: "pages/vendors/payments/statements/index" */))
const _71117b62 = () => interopDefault(import('../pages/vendors/products/index.vue' /* webpackChunkName: "pages/vendors/products/index" */))
const _304a7324 = () => interopDefault(import('../pages/vendors/promotions/index.vue' /* webpackChunkName: "pages/vendors/promotions/index" */))
const _1e6b37a1 = () => interopDefault(import('../pages/vendors/samples/index.vue' /* webpackChunkName: "pages/vendors/samples/index" */))
const _1e25793e = () => interopDefault(import('../pages/vendors/settings/index.vue' /* webpackChunkName: "pages/vendors/settings/index" */))
const _70ab3e20 = () => interopDefault(import('../pages/vendors/stores/index.vue' /* webpackChunkName: "pages/vendors/stores/index" */))
const _0f224b44 = () => interopDefault(import('../pages/vendors/widgets.vue' /* webpackChunkName: "pages/vendors/widgets" */))
const _5114f2ae = () => interopDefault(import('../pages/vendors/claims/create.vue' /* webpackChunkName: "pages/vendors/claims/create" */))
const _4e5b88c8 = () => interopDefault(import('../pages/vendors/products/create.vue' /* webpackChunkName: "pages/vendors/products/create" */))
const _3b325210 = () => interopDefault(import('../pages/vendors/promotions/create.vue' /* webpackChunkName: "pages/vendors/promotions/create" */))
const _04d317bd = () => interopDefault(import('../pages/vendors/promotions/draft.vue' /* webpackChunkName: "pages/vendors/promotions/draft" */))
const _e4267292 = () => interopDefault(import('../pages/vendors/settings/general.vue' /* webpackChunkName: "pages/vendors/settings/general" */))
const _759854f7 = () => interopDefault(import('../pages/vendors/settings/invite-colleagues.vue' /* webpackChunkName: "pages/vendors/settings/invite-colleagues" */))
const _2271b1e6 = () => interopDefault(import('../pages/vendors/settings/minimums.vue' /* webpackChunkName: "pages/vendors/settings/minimums" */))
const _404fcd77 = () => interopDefault(import('../pages/vendors/settings/notifications.vue' /* webpackChunkName: "pages/vendors/settings/notifications" */))
const _8590ea24 = () => interopDefault(import('../pages/vendors/settings/payments.vue' /* webpackChunkName: "pages/vendors/settings/payments" */))
const _40c0087a = () => interopDefault(import('../pages/vendors/orders/canceled/_id.vue' /* webpackChunkName: "pages/vendors/orders/canceled/_id" */))
const _322fb11c = () => interopDefault(import('../pages/vendors/brands/_id.vue' /* webpackChunkName: "pages/vendors/brands/_id" */))
const _405d7346 = () => interopDefault(import('../pages/vendors/claims/_id.vue' /* webpackChunkName: "pages/vendors/claims/_id" */))
const _d38b88ea = () => interopDefault(import('../pages/vendors/orders/_id.vue' /* webpackChunkName: "pages/vendors/orders/_id" */))
const _bfaa236c = () => interopDefault(import('../pages/vendors/products/_id.vue' /* webpackChunkName: "pages/vendors/products/_id" */))
const _7bcbd566 = () => interopDefault(import('../pages/vendors/products/_id/index.vue' /* webpackChunkName: "pages/vendors/products/_id/index" */))
const _56d3a4c3 = () => interopDefault(import('../pages/vendors/products/_id/request-change.vue' /* webpackChunkName: "pages/vendors/products/_id/request-change" */))
const _4cf1758e = () => interopDefault(import('../pages/vendors/products/_id/skus/index.vue' /* webpackChunkName: "pages/vendors/products/_id/skus/index" */))
const _78f186f0 = () => interopDefault(import('../pages/vendors/products/_id/skus/create.vue' /* webpackChunkName: "pages/vendors/products/_id/skus/create" */))
const _2ca96074 = () => interopDefault(import('../pages/vendors/products/_id/skus/_sid/index.vue' /* webpackChunkName: "pages/vendors/products/_id/skus/_sid/index" */))
const _5ab8e754 = () => interopDefault(import('../pages/vendors/promotions/_id.vue' /* webpackChunkName: "pages/vendors/promotions/_id" */))
const _58f4ae49 = () => interopDefault(import('../pages/vendors/samples/_id.vue' /* webpackChunkName: "pages/vendors/samples/_id" */))
const _51bf3cac = () => interopDefault(import('../pages/welcome-vendor.vue' /* webpackChunkName: "pages/welcome-vendor" */))
const _ff36e236 = () => interopDefault(import('../pages/brands/invite.vue' /* webpackChunkName: "pages/brands/invite" */))
const _b9c84b90 = () => interopDefault(import('../pages/capital/plus.vue' /* webpackChunkName: "pages/capital/plus" */))
const _7168bd00 = () => interopDefault(import('../pages/capital/term-loans.vue' /* webpackChunkName: "pages/capital/term-loans" */))
const _fe0e5254 = () => interopDefault(import('../pages/pricing/earnings-calculator.vue' /* webpackChunkName: "pages/pricing/earnings-calculator" */))
const _1876cc19 = () => interopDefault(import('../pages/services/brands/index.vue' /* webpackChunkName: "pages/services/brands/index" */))
const _c32204ae = () => interopDefault(import('../pages/services/retailers.vue' /* webpackChunkName: "pages/services/retailers" */))
const _4c5c839c = () => interopDefault(import('../pages/services/brands/direct.vue' /* webpackChunkName: "pages/services/brands/direct" */))
const _617bf812 = () => interopDefault(import('../pages/services/brands/express.vue' /* webpackChunkName: "pages/services/brands/express" */))
const _6b01590b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _19dd888d = () => interopDefault(import('../pages/invoices/buyers/orders/_id.vue' /* webpackChunkName: "pages/invoices/buyers/orders/_id" */))
const _49bb0187 = () => interopDefault(import('../pages/invoices/buyers/samples/_id.vue' /* webpackChunkName: "pages/invoices/buyers/samples/_id" */))
const _0fd70aae = () => interopDefault(import('../pages/invoices/headBuyer/orders/_id.vue' /* webpackChunkName: "pages/invoices/headBuyer/orders/_id" */))
const _12f1c386 = () => interopDefault(import('../pages/invoices/headBuyer/samples/_id.vue' /* webpackChunkName: "pages/invoices/headBuyer/samples/_id" */))
const _1c555773 = () => interopDefault(import('../pages/invoices/lp/order/_id/index.vue' /* webpackChunkName: "pages/invoices/lp/order/_id/index" */))
const _aa25cc94 = () => interopDefault(import('../pages/invoices/vendors/orders/_id.vue' /* webpackChunkName: "pages/invoices/vendors/orders/_id" */))
const _4ac4d504 = () => interopDefault(import('../pages/invoices/vendors/samples/_id.vue' /* webpackChunkName: "pages/invoices/vendors/samples/_id" */))
const _17dcae0e = () => interopDefault(import('../pages/invoices/lp/order/_id/invoice.vue' /* webpackChunkName: "pages/invoices/lp/order/_id/invoice" */))
const _0d82fca2 = () => interopDefault(import('../pages/invoices/lp/order/_id/slip.vue' /* webpackChunkName: "pages/invoices/lp/order/_id/slip" */))
const _798498f4 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _f8ef57e4 = () => interopDefault(import('../pages/brands/_id.vue' /* webpackChunkName: "pages/brands/_id" */))
const _d51b3834 = () => interopDefault(import('../pages/products/_id.vue' /* webpackChunkName: "pages/products/_id" */))
const _cf6c7d96 = () => interopDefault(import('~/pages/brand' /* webpackChunkName: "" */))
const _db92e1c6 = () => interopDefault(import('~/pages/product' /* webpackChunkName: "" */))
const _224ce406 = () => interopDefault(import('~/pages/setup-password' /* webpackChunkName: "" */))
const _16c8cba8 = () => interopDefault(import('~/pages/accept-invitation' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _4b497e46,
    name: "about"
  }, {
    path: "/accept-invitation",
    component: _1bbd6b92,
    name: "accept-invitation"
  }, {
    path: "/blog",
    component: _7b3b3a3c,
    name: "blog"
  }, {
    path: "/bloomberg-2024",
    component: _ba7bed2e,
    name: "bloomberg-2024"
  }, {
    path: "/brand",
    component: _6c361940,
    name: "brand"
  }, {
    path: "/brands",
    component: _1aa6ffb4,
    name: "brands"
  }, {
    path: "/brokers",
    component: _7856489a,
    name: "brokers",
    children: [{
      path: "settings/general",
      component: _6dd1e688,
      name: "brokers-settings-general"
    }, {
      path: "settings/notifications",
      component: _1ccfe6f0,
      name: "brokers-settings-notifications"
    }]
  }, {
    path: "/buyers",
    component: _58241592,
    children: [{
      path: "",
      component: _3625767a,
      name: "buyers"
    }, {
      path: "analytics",
      component: _0bcc599e,
      name: "buyers-analytics"
    }, {
      path: "orders",
      component: _7244aca0,
      name: "buyers-orders"
    }, {
      path: "payments",
      component: _a7864010,
      name: "buyers-payments"
    }, {
      path: "samples",
      component: _4375b3f4,
      name: "buyers-samples"
    }, {
      path: "settings",
      component: _6d32516e,
      name: "buyers-settings"
    }, {
      path: "payments/credit-memos",
      component: _73c2d16f,
      name: "buyers-payments-credit-memos"
    }, {
      path: "payments/statements",
      component: _257a069e,
      name: "buyers-payments-statements"
    }, {
      path: "settings/general",
      component: _0d268584,
      name: "buyers-settings-general"
    }, {
      path: "settings/invite-colleagues",
      component: _0d4090f8,
      name: "buyers-settings-invite-colleagues"
    }, {
      path: "settings/notifications",
      component: _25bf02f8,
      name: "buyers-settings-notifications"
    }, {
      path: "settings/payment",
      component: _7cf3efa2,
      name: "buyers-settings-payment"
    }, {
      path: "orders/list/:type?",
      component: _ee47bb0c,
      name: "buyers-orders-list-type"
    }, {
      path: "orders/pre-order/:id?",
      component: _4263792d,
      name: "buyers-orders-pre-order-id"
    }, {
      path: "samples/list/:type?",
      component: _a0783a94,
      name: "buyers-samples-list-type"
    }, {
      path: "orders/:id",
      component: _0fab4fd0,
      name: "buyers-orders-id"
    }, {
      path: "samples/:id",
      component: _3508235c,
      name: "buyers-samples-id"
    }]
  }, {
    path: "/capital",
    component: _29f1e7b8,
    name: "capital"
  }, {
    path: "/careers",
    component: _6d5f4b2e,
    name: "careers"
  }, {
    path: "/cart",
    component: _265c8ef7,
    name: "cart"
  }, {
    path: "/checkout",
    component: _cf81b2c6,
    name: "checkout"
  }, {
    path: "/claim",
    component: _edabd916,
    name: "claim"
  }, {
    path: "/claims",
    component: _c0780764,
    name: "claims"
  }, {
    path: "/faq",
    component: _65f42d8f,
    children: [{
      path: "",
      component: _37c60cd2,
      name: "faq"
    }, {
      path: "brands",
      component: _2beb1608,
      name: "faq-brands"
    }, {
      path: "retailers",
      component: _24eb0503,
      name: "faq-retailers"
    }]
  }, {
    path: "/favorites",
    component: _a08dd0e0,
    name: "favorites"
  }, {
    path: "/growth-deck",
    component: _49bc4430,
    name: "growth-deck"
  }, {
    path: "/head-buyer",
    component: _4db8411d,
    name: "head-buyer",
    children: [{
      path: "analytics",
      component: _46e8d9d2,
      name: "head-buyer-analytics"
    }, {
      path: "fill-rate",
      component: _0ab5050a,
      name: "head-buyer-fill-rate"
    }, {
      path: "inventory-velocity",
      component: _5a096eda,
      name: "head-buyer-inventory-velocity"
    }, {
      path: "orders",
      component: _26af38ec,
      name: "head-buyer-orders"
    }, {
      path: "payments",
      component: _eb7ee95c,
      name: "head-buyer-payments"
    }, {
      path: "samples",
      component: _51fb954c,
      name: "head-buyer-samples"
    }, {
      path: "settings",
      component: _4b35fcc8,
      name: "head-buyer-settings"
    }, {
      path: "analytics/avg-drop-size",
      component: _40726329,
      name: "head-buyer-analytics-avg-drop-size"
    }, {
      path: "analytics/avg-order-size",
      component: _718217f4,
      name: "head-buyer-analytics-avg-order-size"
    }, {
      path: "analytics/best-selling-brands",
      component: _6d837d12,
      name: "head-buyer-analytics-best-selling-brands"
    }, {
      path: "analytics/fill-rate",
      component: _2b921aa2,
      name: "head-buyer-analytics-fill-rate"
    }, {
      path: "analytics/number-of-orders",
      component: _7ce2333c,
      name: "head-buyer-analytics-number-of-orders"
    }, {
      path: "analytics/sale-velocity",
      component: _f3980730,
      name: "head-buyer-analytics-sale-velocity"
    }, {
      path: "analytics/stock-report",
      component: _f2cf08d4,
      name: "head-buyer-analytics-stock-report"
    }, {
      path: "orders/create-multiple",
      component: _3b49b20e,
      name: "head-buyer-orders-create-multiple"
    }, {
      path: "payments/credit-memos",
      component: _4a828cd5,
      name: "head-buyer-payments-credit-memos"
    }, {
      path: "payments/statements",
      component: _c56ad5d2,
      name: "head-buyer-payments-statements"
    }, {
      path: "settings/general",
      component: _78ecc25e,
      name: "head-buyer-settings-general"
    }, {
      path: "orders/pre-order/:id?",
      component: _96a88df2,
      name: "head-buyer-orders-pre-order-id"
    }, {
      path: "samples/list/:type?",
      component: _42ee5110,
      name: "head-buyer-samples-list-type"
    }, {
      path: "orders/:id",
      component: _512dc072,
      name: "head-buyer-orders-id"
    }, {
      path: "samples/:id",
      component: _071c737c,
      name: "head-buyer-samples-id"
    }]
  }, {
    path: "/help-center",
    component: _4586805a,
    children: [{
      path: "",
      component: _5363db5d,
      name: "help-center"
    }, {
      path: "search",
      component: _76532ca6,
      name: "help-center-search"
    }, {
      path: ":slug",
      component: _51ad3a15,
      name: "help-center-slug"
    }]
  }, {
    path: "/login",
    component: _34a8fc22,
    name: "login"
  }, {
    path: "/lp",
    component: _588f931b,
    children: [{
      path: "",
      component: _4d6a195e,
      name: "lp"
    }, {
      path: "inventory",
      component: _e6b9ca30,
      children: [{
        path: "",
        component: _a64c592a,
        name: "lp-inventory"
      }, {
        path: "all",
        component: _4db5d3fd,
        name: "lp-inventory-all"
      }, {
        path: "dispose-donate",
        component: _67914197,
        name: "lp-inventory-dispose-donate"
      }, {
        path: "inbound",
        component: _70685f55,
        name: "lp-inventory-inbound"
      }, {
        path: "withdrawal-requests",
        component: _62fd6710,
        name: "lp-inventory-withdrawal-requests"
      }, {
        path: "all/new",
        component: _5368868b,
        name: "lp-inventory-all-new"
      }, {
        path: "all/:id",
        component: _3fb2b1a5,
        name: "lp-inventory-all-id"
      }, {
        path: "dispose-donate/:id?",
        component: _263e8482,
        name: "lp-inventory-dispose-donate-id"
      }, {
        path: "inbound/:id",
        component: _2fc032fd,
        name: "lp-inventory-inbound-id"
      }, {
        path: "withdrawal-requests/:id?",
        component: _18680ce0,
        name: "lp-inventory-withdrawal-requests-id"
      }]
    }, {
      path: "orders",
      component: _83ea8368,
      name: "lp-orders"
    }, {
      path: "pod-freight",
      component: _7c5070c7,
      children: [{
        path: "",
        component: _25fdf3ec,
        name: "lp-pod-freight"
      }, {
        path: ":id",
        component: _c765fa1c,
        name: "lp-pod-freight-id"
      }]
    }, {
      path: "settings",
      component: _4d6caf0a,
      name: "lp-settings"
    }, {
      path: "settings/general",
      component: _6f8027c0,
      name: "lp-settings-general"
    }, {
      path: "orders/:id",
      component: _7ce83898,
      name: "lp-orders-id"
    }]
  }, {
    path: "/order-guide",
    component: _88d3ac94,
    name: "order-guide"
  }, {
    path: "/ordered-variants",
    component: _e3de6776,
    name: "ordered-variants"
  }, {
    path: "/pod-3pl",
    component: _2b3cc360,
    name: "pod-3pl"
  }, {
    path: "/pod-cost",
    component: _dd4d07a8,
    name: "pod-cost"
  }, {
    path: "/pod-freight",
    component: _34d27ab4,
    name: "pod-freight"
  }, {
    path: "/pod-insights",
    component: _eea25c8c,
    name: "pod-insights"
  }, {
    path: "/pod-pax",
    component: _a5ad6ad0,
    name: "pod-pax"
  }, {
    path: "/podcasts",
    component: _781114c9,
    name: "podcasts"
  }, {
    path: "/press",
    component: _64e8b3fc,
    name: "press"
  }, {
    path: "/pricing",
    component: _7a110d02,
    name: "pricing"
  }, {
    path: "/privacy",
    component: _0ee836fe,
    name: "privacy"
  }, {
    path: "/product",
    component: _46078268,
    name: "product"
  }, {
    path: "/products",
    component: _3251f9fe,
    name: "products"
  }, {
    path: "/promotions",
    component: _aea90e72,
    name: "promotions"
  }, {
    path: "/recommended-products",
    component: _0fcb776d,
    name: "recommended-products"
  }, {
    path: "/referral",
    component: _85d55d18,
    name: "referral"
  }, {
    path: "/register",
    component: _623a147d,
    name: "register"
  }, {
    path: "/reset-password",
    component: _6aaeb5f0,
    name: "reset-password"
  }, {
    path: "/retailers",
    component: _74c38cbc,
    name: "retailers"
  }, {
    path: "/setup-password",
    component: _2711f382,
    name: "setup-password"
  }, {
    path: "/state-of-grocery",
    component: _c548bcac,
    name: "state-of-grocery"
  }, {
    path: "/team",
    component: _7af72098,
    name: "team"
  }, {
    path: "/terms-of-service",
    component: _656e7b9c,
    children: [{
      path: "",
      component: _097edd1f,
      name: "terms-of-service"
    }, {
      path: "brands",
      component: _4f6bae4f,
      name: "terms-of-service-brands"
    }, {
      path: "logistics-partners",
      component: _151a92b0,
      name: "terms-of-service-logistics-partners"
    }, {
      path: "retailers",
      component: _5c0e3260,
      name: "terms-of-service-retailers"
    }]
  }, {
    path: "/terms-of-use",
    component: _c734fe24,
    name: "terms-of-use"
  }, {
    path: "/vendors",
    component: _b8ec88b8,
    children: [{
      path: "",
      component: _3d5bf727,
      name: "vendors"
    }, {
      path: "analytics",
      component: _5c34495b,
      children: [{
        path: "",
        component: _2485c0c4,
        name: "vendors-analytics"
      }, {
        path: "customer",
        component: _106399c2,
        children: [{
          path: "",
          component: _845b7276,
          name: "vendors-analytics-customer"
        }, {
          path: "churn",
          component: _6ddca3df,
          name: "vendors-analytics-customer-churn"
        }, {
          path: "cohort",
          component: _fdfbf9dc,
          name: "vendors-analytics-customer-cohort"
        }, {
          path: "first-time-and-returning",
          component: _94e20554,
          name: "vendors-analytics-customer-first-time-and-returning"
        }, {
          path: "growth-accounting",
          component: _3f933b4e,
          name: "vendors-analytics-customer-growth-accounting"
        }, {
          path: "one-time",
          component: _24698f9e,
          name: "vendors-analytics-customer-one-time"
        }, {
          path: "over-time",
          component: _759aca99,
          name: "vendors-analytics-customer-over-time"
        }, {
          path: "sales-by-region",
          component: _c1361348,
          name: "vendors-analytics-customer-sales-by-region"
        }]
      }, {
        path: "inventory",
        component: _23890128,
        children: [{
          path: "",
          component: _526becaa,
          name: "vendors-analytics-inventory"
        }, {
          path: "average-sold-per-week",
          component: _51d11926,
          name: "vendors-analytics-inventory-average-sold-per-week"
        }, {
          path: "current",
          component: _5f252692,
          name: "vendors-analytics-inventory-current"
        }, {
          path: "days-of-remaining",
          component: _03216c8f,
          name: "vendors-analytics-inventory-days-of-remaining"
        }, {
          path: "lost-sales",
          component: _21941b9a,
          name: "vendors-analytics-inventory-lost-sales"
        }, {
          path: "month-end",
          component: _dcb38b32,
          name: "vendors-analytics-inventory-month-end"
        }, {
          path: "percent-of-sold",
          component: _dd6e15ca,
          name: "vendors-analytics-inventory-percent-of-sold"
        }, {
          path: "replenishment-lead-time",
          component: _3254cea4,
          name: "vendors-analytics-inventory-replenishment-lead-time"
        }]
      }, {
        path: "lead-time",
        component: _db40f52c,
        children: [{
          path: "",
          component: _b1ee5726,
          name: "vendors-analytics-lead-time"
        }, {
          path: "pd-order-fulfillment",
          component: _9bc6350a,
          name: "vendors-analytics-lead-time-pd-order-fulfillment"
        }, {
          path: "replenishment",
          component: _df0a99c2,
          name: "vendors-analytics-lead-time-replenishment"
        }]
      }, {
        path: "promotion",
        component: _add62e22,
        children: [{
          path: "",
          component: _b2f67f9c,
          name: "vendors-analytics-promotion"
        }, {
          path: "leading-to-repurchase",
          component: _095835c2,
          name: "vendors-analytics-promotion-leading-to-repurchase"
        }, {
          path: "usage",
          component: _04cb1581,
          name: "vendors-analytics-promotion-usage"
        }]
      }, {
        path: "sale",
        component: _c4d2f8aa,
        children: [{
          path: "",
          component: _62034424,
          name: "vendors-analytics-sale"
        }, {
          path: "avg-order-value",
          component: _65108153,
          name: "vendors-analytics-sale-avg-order-value"
        }, {
          path: "fulfilled-gmv",
          component: _1403cfbe,
          name: "vendors-analytics-sale-fulfilled-gmv"
        }, {
          path: "fulfilled-revenue",
          component: _37c7628a,
          name: "vendors-analytics-sale-fulfilled-revenue"
        }, {
          path: "ordered-gmv",
          component: _1154420c,
          name: "vendors-analytics-sale-ordered-gmv"
        }, {
          path: "ordered-revenue",
          component: _57d9fdd8,
          name: "vendors-analytics-sale-ordered-revenue"
        }, {
          path: "sku-performance",
          component: _176096c8,
          name: "vendors-analytics-sale-sku-performance"
        }]
      }]
    }, {
      path: "brands",
      component: _0535eaec,
      name: "vendors-brands"
    }, {
      path: "claims",
      component: _60821ab5,
      name: "vendors-claims"
    }, {
      path: "inventory",
      component: _516eec31,
      children: [{
        path: "",
        component: _796bc9f4,
        name: "vendors-inventory"
      }, {
        path: "all",
        component: _2631147a,
        name: "vendors-inventory-all"
      }, {
        path: "dispose-donate",
        component: _adb77024,
        name: "vendors-inventory-dispose-donate"
      }, {
        path: "inbound",
        component: _3743bcde,
        name: "vendors-inventory-inbound"
      }, {
        path: "status",
        component: _ce24f980,
        name: "vendors-inventory-status"
      }, {
        path: "transfer",
        component: _71dd28fc,
        name: "vendors-inventory-transfer"
      }, {
        path: "withdrawal-requests",
        component: _6257a481,
        name: "vendors-inventory-withdrawal-requests"
      }, {
        path: "dispose-donate/create",
        component: _b9cdfee0,
        name: "vendors-inventory-dispose-donate-create"
      }, {
        path: "inbound/create",
        component: _4e2facc0,
        name: "vendors-inventory-inbound-create"
      }, {
        path: "withdrawal-requests/create",
        component: _1051365d,
        name: "vendors-inventory-withdrawal-requests-create"
      }, {
        path: "dispose-donate/:id?",
        component: _59412454,
        name: "vendors-inventory-dispose-donate-id"
      }, {
        path: "inbound/:id",
        component: _17b75ec6,
        name: "vendors-inventory-inbound-id"
      }, {
        path: "transfer/:id",
        component: _994d8b38,
        name: "vendors-inventory-transfer-id"
      }, {
        path: "withdrawal-requests/:id?",
        component: _0cd6d329,
        name: "vendors-inventory-withdrawal-requests-id"
      }]
    }, {
      path: "notifications",
      component: _756d4986,
      name: "vendors-notifications"
    }, {
      path: "orders",
      component: _20827c63,
      name: "vendors-orders"
    }, {
      path: "payments",
      component: _43c1f968,
      children: [{
        path: "",
        component: _2ff7d1eb,
        name: "vendors-payments"
      }, {
        path: "commission",
        component: _02e59b22,
        name: "vendors-payments-commission"
      }, {
        path: "payouts",
        component: _669f6234,
        name: "vendors-payments-payouts"
      }, {
        path: "statements",
        component: _3b9764de,
        name: "vendors-payments-statements"
      }]
    }, {
      path: "products",
      component: _71117b62,
      name: "vendors-products"
    }, {
      path: "promotions",
      component: _304a7324,
      name: "vendors-promotions"
    }, {
      path: "samples",
      component: _1e6b37a1,
      name: "vendors-samples"
    }, {
      path: "settings",
      component: _1e25793e,
      name: "vendors-settings"
    }, {
      path: "stores",
      component: _70ab3e20,
      name: "vendors-stores"
    }, {
      path: "widgets",
      component: _0f224b44,
      name: "vendors-widgets"
    }, {
      path: "claims/create",
      component: _5114f2ae,
      name: "vendors-claims-create"
    }, {
      path: "products/create",
      component: _4e5b88c8,
      name: "vendors-products-create"
    }, {
      path: "promotions/create",
      component: _3b325210,
      name: "vendors-promotions-create"
    }, {
      path: "promotions/draft",
      component: _04d317bd,
      name: "vendors-promotions-draft"
    }, {
      path: "settings/general",
      component: _e4267292,
      name: "vendors-settings-general"
    }, {
      path: "settings/invite-colleagues",
      component: _759854f7,
      name: "vendors-settings-invite-colleagues"
    }, {
      path: "settings/minimums",
      component: _2271b1e6,
      name: "vendors-settings-minimums"
    }, {
      path: "settings/notifications",
      component: _404fcd77,
      name: "vendors-settings-notifications"
    }, {
      path: "settings/payments",
      component: _8590ea24,
      name: "vendors-settings-payments"
    }, {
      path: "orders/canceled/:id?",
      component: _40c0087a,
      name: "vendors-orders-canceled-id"
    }, {
      path: "brands/:id",
      component: _322fb11c,
      name: "vendors-brands-id"
    }, {
      path: "claims/:id",
      component: _405d7346,
      name: "vendors-claims-id"
    }, {
      path: "orders/:id",
      component: _d38b88ea,
      name: "vendors-orders-id"
    }, {
      path: "products/:id",
      component: _bfaa236c,
      children: [{
        path: "",
        component: _7bcbd566,
        name: "vendors-products-id"
      }, {
        path: "request-change",
        component: _56d3a4c3,
        name: "vendors-products-id-request-change"
      }, {
        path: "skus",
        component: _4cf1758e,
        name: "vendors-products-id-skus"
      }, {
        path: "skus/create",
        component: _78f186f0,
        name: "vendors-products-id-skus-create"
      }, {
        path: "skus/:sid",
        component: _2ca96074,
        name: "vendors-products-id-skus-sid"
      }]
    }, {
      path: "promotions/:id",
      component: _5ab8e754,
      name: "vendors-promotions-id"
    }, {
      path: "samples/:id",
      component: _58f4ae49,
      name: "vendors-samples-id"
    }]
  }, {
    path: "/welcome-vendor",
    component: _51bf3cac,
    name: "welcome-vendor"
  }, {
    path: "/brands/invite",
    component: _ff36e236,
    name: "brands-invite"
  }, {
    path: "/capital/plus",
    component: _b9c84b90,
    name: "capital-plus"
  }, {
    path: "/capital/term-loans",
    component: _7168bd00,
    name: "capital-term-loans"
  }, {
    path: "/pricing/earnings-calculator",
    component: _fe0e5254,
    name: "pricing-earnings-calculator"
  }, {
    path: "/services/brands",
    component: _1876cc19,
    name: "services-brands"
  }, {
    path: "/services/retailers",
    component: _c32204ae,
    name: "services-retailers"
  }, {
    path: "/services/brands/direct",
    component: _4c5c839c,
    name: "services-brands-direct"
  }, {
    path: "/services/brands/express",
    component: _617bf812,
    name: "services-brands-express"
  }, {
    path: "/",
    component: _6b01590b,
    name: "index"
  }, {
    path: "/invoices/buyers/orders/:id?",
    component: _19dd888d,
    name: "invoices-buyers-orders-id"
  }, {
    path: "/invoices/buyers/samples/:id?",
    component: _49bb0187,
    name: "invoices-buyers-samples-id"
  }, {
    path: "/invoices/headBuyer/orders/:id?",
    component: _0fd70aae,
    name: "invoices-headBuyer-orders-id"
  }, {
    path: "/invoices/headBuyer/samples/:id?",
    component: _12f1c386,
    name: "invoices-headBuyer-samples-id"
  }, {
    path: "/invoices/lp/order/:id",
    component: _1c555773,
    name: "invoices-lp-order-id"
  }, {
    path: "/invoices/vendors/orders/:id?",
    component: _aa25cc94,
    name: "invoices-vendors-orders-id"
  }, {
    path: "/invoices/vendors/samples/:id?",
    component: _4ac4d504,
    name: "invoices-vendors-samples-id"
  }, {
    path: "/invoices/lp/order/:id?/invoice",
    component: _17dcae0e,
    name: "invoices-lp-order-id-invoice"
  }, {
    path: "/invoices/lp/order/:id?/slip",
    component: _0d82fca2,
    name: "invoices-lp-order-id-slip"
  }, {
    path: "/blog/:slug",
    component: _798498f4,
    name: "blog-slug"
  }, {
    path: "/brands/:id",
    component: _f8ef57e4,
    name: "brands-id"
  }, {
    path: "/products/:id",
    component: _d51b3834,
    name: "products-id"
  }, {
    path: "/:slug-b:id(\\d+).html",
    component: _cf6c7d96,
    name: "_brand"
  }, {
    path: "/:slug-p:id(\\d+).html",
    component: _db92e1c6,
    name: "_product"
  }, {
    path: "/setup-password/:user_role",
    component: _224ce406,
    name: "_setup_password"
  }, {
    path: "/vendor/vendor_auth/invitation/accept",
    component: _16c8cba8,
    name: "_vendor_accept_invitation"
  }, {
    path: "/buyer/buyer_auth/invitation/accept",
    component: _16c8cba8,
    name: "_buyer_accept_invitation"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
