import objectToFormData from '~/utilities/object-to-formdata'

export default ({ $moment, $axios }) => ({
  referBrands: data =>
    $axios.$post('/head_buyer/referral_brand_forms.json', {
      referral_brand_form: data
    }),

  changePassword: data =>
    $axios.$put('/head_buyer/buyer_auth.json', data),

  getPersonalInformation: () =>
    $axios.$get('/head_buyer/head_buyers.json'),

  updatePersonalInformation: data =>
    $axios.$put('/head_buyer/head_buyers.json', { head_buyer: data }),

  addItemToFavorites: id =>
    $axios.$post('/head_buyer/favorite_products.json', { favorite_product: { product_variant_id: id } }),

  getFavoriteItems: (params) =>
    $axios.$get('/head_buyer/favorite_products.json', { params }),

  removeFavoriteItem: id =>
    $axios.$delete(`/head_buyer/favorite_products/${id}.json`),

  getRecommendedProducts: params =>
    $axios.$get('/head_buyer/recommended_product/products.json', { params }),

  exportRecommendedProducts: params =>
    $axios.$get('/head_buyer/export/recommended_product/products.json', { params }),

  exportOrdersGuide: params =>
    $axios.$get('/head_buyer/export/order_guide/ordered_variants.json', { params, responseType: 'blob' }),

  getOrderHistory: params =>
    $axios.$get('/head_buyer/order_guide/ordered_variants.json', { params }),

  getStores: (params) =>
    $axios.$get('/head_buyer/stores.json', { params }),

  updateStore: ({ id, ...data }) =>
    $axios.$put(`/head_buyer/stores/${id}.json`, data),

  getOrders: params =>
    $axios.$get('/head_buyer/orders.json', { params }),

  exportOrders: params =>
    $axios.$get('/head_buyer/export/orders.json', { responseType: 'blob' }),

  getOrderDetails: id =>
    $axios.$get(`/head_buyer/orders/${id}.json`),

  getPreOrderDetails: (id) =>
    $axios.$get(`/head_buyer/pre_order/orders/${id}.json`),

  getSubInvoiceDetails: id =>
    $axios.$get(`/head_buyer/sub_invoices/${id}.json`),

  getSampleRequests: params =>
    $axios.$get('/head_buyer/sample_requests.json', { params }),

  getSampleDetails: id =>
    $axios.$get(`/head_buyer/sample_requests/${id}.json`),

  getCreditMemos: (params) =>
    $axios.get('/head_buyer/credit_memos.json', { params }),

  getCompanies: () =>
    $axios.$get('/head_buyer/buyer_companies.json'),

  updateCompanyInformation: data =>
    $axios.$put('/head_buyer/buyer_companies.json', objectToFormData({
      buyer_company: data
    })),

  getSalesTrendOrders: (params) =>
    $axios.$get('/head_buyer/analytics/orders/sales_trend.json', { params }),

  getTotalCasePurchased: (params) =>
    $axios.$get('head_buyer/analytics/orders/total_case_purchased.json', { params }),

  getTotalValuePurchased: (params) =>
    $axios.$get('head_buyer/analytics/orders/total_value_purchased.json', { params }),

  getOrderedBrands: (params) =>
    $axios.$get('head_buyer/analytics/brands.json', { params }),

  getSalesMap: (params) =>
    $axios.$get('/head_buyer/analytics/stores/sales_map.json', { params }),

  getAverageOrderSize: (params) =>
    $axios.$get('/head_buyer/analytics/orders/avg_size.json', { params }),

  getSalesByCategory: (params) =>
    $axios.$get('/head_buyer/analytics/product_categories.json', { params }),

  getStoresOrderValues: (params) =>
    $axios.$get('/head_buyer/analytics/stores.json', { params }),

  getBrandRecommended: (params) =>
    $axios.$get('/head_buyer/analytics/brands/recommended.json', { params }),

  getSupportStores: (params) =>
    $axios.$get('/head_buyer/supports/order_values/stores.json', { params }),

  getSubInvoices: (params) =>
    $axios.$get('/head_buyer/supports/sub_invoices.json', { params }),

  claims: (data) =>
    $axios.$post('/head_buyer/claims.json', objectToFormData({ claim: data })),

  getRegions: (params) =>
    $axios.$get('/head_buyer/supports/order_values/regions.json ', { params }),

  getAddresses: () =>
    $axios.$get('/head_buyer/addresses.json'),

  createAddress: (data) =>
    $axios.$post('/head_buyer/addresses.json', data).then(res => res.address_attributes),

  updateAddress: (data) =>
    $axios.$put(`/head_buyer/addresses/${data.id}.json`, data),

  deleteAddress: (id) =>
    $axios.$delete(`/head_buyer/addresses/${id}.json`),

  createSampleRequest: data =>
    $axios.$post('/head_buyer/sample_requests.json', data),

  getStatements: params =>
    $axios.$get('/head_buyer/store_statements.json', { params }),

  getStatementDetail: id =>
    $axios.$get(`/head_buyer/store_statements/${id}.json`),

  exportStatementPDF: id =>
    $axios.$get(`/head_buyer/export/pdf/store_statements/${id}`, { responseType: 'blob' }),

  exportStatementXlsx: id =>
    $axios.$get(`/head_buyer/export/excel/store_statements/${id}.xlsx`, { responseType: 'blob' }),

  getInventoriesVelocity: params =>
    $axios.$get('/head_buyer/head_buyer_inventories.json', { params }),

  exportInventoriesVelocity: params =>
    $axios.$get('/head_buyer/export/head_buyer_inventories.json', { params }),

  getFillRate: params =>
    $axios.$get('/head_buyer/analytics/orders/fill_rates.json', { params }),

  // These BE apis (searchBrands + getBrandDetails) will allow return inactive brands as well. (From task 2540 bug 4)
  searchBrands: (params) =>
    $axios.$get('/head_buyer/supports/brands.json', { params }),

  getBrandDetails: (id) =>
    $axios.$get(`/head_buyer/supports/brands/${id}.json`),

  getProductVariants: params =>
    $axios.$get('/head_buyer/product_variants.json', { params }),

  getProductVariantsInfo: params =>
    $axios.$get('/head_buyer/multiple/product_variants.json', { params })
      .then(({ results }) => results),

  previewOrders: order =>
    $axios.$post('/head_buyer/multiple/orders/preview.json', { order })
      .then(({ results }) => results),

  createOrder: order =>
    $axios.$post('/head_buyer/orders.json', { order }),

  createMultipleOrder: order =>
    $axios.$post('/head_buyer/multiple/orders.json', { order }),

  getAnalytics: params =>
    $axios.$get('/head_buyer/analytics/statistical_data.json', { params }),

  exportAnalytics: params =>
    $axios.$get('/head_buyer/export/statistical_data.json', { params }),

  getWeeklyPromo: () =>
    $axios.$get('/head_buyer/weekly_ads.json', { params: { date: $moment().format('YYYY/MM/DD') } })
    .then(({ results }) => results[0])

})
